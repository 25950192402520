<template>
  <v-container fluid>
    <v-widget title="Usuários">
      <template v-slot:toolbar>
        <v-container fluid>
          <v-row dense>
            <v-col cols="10" sm="5">
              <v-text-field filled flat single-line hide-details dense clearable rounded v-model="busca" @click:clear="limparBusca" max-width="300px" prepend-inner-icon="search" :label="constantes.BOTAO_FILTRAR_USUARIO"></v-text-field>
            </v-col>

            <v-col cols="2" sm="7" class="text-right">
              <v-btn v-if="!isSm" depressed color="primary" @click="adicionar"> NOVO </v-btn>

              <v-btn v-else depressed fab small color="primary" @click="adicionar">
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </template>

      <template v-slot:widget-content>
        <v-data-table :headers="headers" :items="usuarios" :search="busca" :loading="carregando" mobile-breakpoint="0">
          <template v-slot:item="{ item: usuario }">
            <tr>
              <td>{{ usuario.nome }}</td>
              <td>{{ usuario.email }}</td>
              <td>
                <v-checkbox class="ma-0 ml-2" v-model="usuario.status" color="primary" hide-details filled v-if="usuario.email !== $store.state.autenticacao.session.empresa.email" @click="status(usuario)" />
              </td>

              <td class="text-center">
                <v-menu top right transition="slide-x-transition" @click.native.stop="" v-if="usuario.email !== $store.state.autenticacao.session.empresa.email">
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" data-cy="menu-acoes">more_vert</v-icon>
                  </template>
                  <v-list dense>
                    <v-list-item @click="editar(usuario)" data-cy="editar">
                      <v-icon class="mr-4">edit</v-icon>
                      <v-list-item-title>Editar</v-list-item-title>
                    </v-list-item>
                    <v-divider></v-divider>
                    <v-list-item @click="remover(usuario)" data-cy="excluir">
                      <v-icon class="mr-4">delete</v-icon>
                      <v-list-item-title>Excluir</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </td>
            </tr>
          </template>
        </v-data-table>
      </template>
    </v-widget>
  </v-container>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import constantes from '@/util/constantes';

export default {
  data() {
    return {
      headers: [
        { text: 'Nome', value: 'nome' },
        { text: 'Usuário', value: 'email' },
        { text: 'Ativo', value: 'status', align: 'center', width: '32px' },
        { text: '', value: 'name', align: 'center', width: '32px', sortable: false },
      ],
      constantes: constantes,
      busca: '',
    };
  },

  computed: {
    ...mapState('usuario', {
      usuarios: 'usuario',
      carregando: 'carregando',
    }),

    isSm() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },

  methods: {
    ...mapActions('usuario', {
      listar: 'listar',
    }),

    limparBusca() {
      this.busca = '';
    },

    editar(usuario) {
      this.$store.commit('usuario/editar', usuario);
      this.$router.push({ name: 'usuarioEditar' });
    },

    adicionar() {
      this.$store.commit('usuario/adicionar');
      this.$router.push({ name: 'usuarioAdicionar' });
    },

    status(usuario) {
      this.$store.commit('usuario/editar', { ...usuario, status: usuario.status === 'ativo' ? null : 'ativo' });
      this.$store.dispatch('usuario/salvar', usuario.empresas);
    },

    remover(usuario) {
      this.$root.$confirmacao.open('Excluir', 'Tem certeza que deseja excluir esse usuário?').then((confirmado) => {
        if (confirmado) {
          this.$store.dispatch('usuario/remover', usuario);
        }
      });
    },
  },

  created() {
    this.listar();
  },
};
</script>
