<template>
  <v-container fluid>
    <v-widget title="Lista Filiais">
      <template v-slot:toolbar>
        <v-container fluid>
          <v-row dense>
            <v-col cols="10" sm="5">
              <v-text-field filled flat single-line hide-details dense clearable rounded v-model="busca" max-width="300px" prepend-inner-icon="search" label="Busca"></v-text-field>
            </v-col>
            <v-col cols="2" sm="7" class="text-right">
              <v-btn v-if="!isSm && $store.state.autenticacao.session.usuario.nivelAcesso === 'ADMINISTRADOR'" depressed color="primary" @click="novaFilial"> NOVA </v-btn>
              <v-btn v-if="isSm && $store.state.autenticacao.session.usuario.nivelAcesso === 'ADMINISTRADOR'" depressed fab small color="primary" @click="novo">
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </template>
      <template v-slot:widget-content>
        <v-data-table :headers="headers" :items="filiais" :search="busca" :loading="carregando" mobile-breakpoint="0">
          <template v-slot:item="props">
            <tr>
              <td class="text-no-wrap">{{ props.item.cnpj | formatarCnpj }}</td>
              <td>{{ props.item.razao }}</td>
              <td>
                <v-chip chip color="primary">{{ constantes.TIPOS_MODO.find((modo) => modo.value === props.item.plano).text }}</v-chip>
              </td>
              <td>{{ props.item.ambiente | codigoParaAmbiente }}</td>
              <td v-if="props.item._id === $store.state.empresa._id"><v-icon color="green lighten-1">gps_fixed</v-icon></td>
              <td v-else><v-icon @click="alterarEmpresa(props.item)">gps_not_fixed</v-icon></td>
              <td class="text-center">
                <v-menu top right transition="slide-x-transition" @click.native.stop="" v-if="props.item._id !== $store.state.empresa._id">
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on">more_vert</v-icon>
                  </template>
                  <v-list dense>
                    <v-list-item :key="props.item._id" @click="editarFilial(props.item)" data-cy="editar">
                      <v-list-item-icon><v-icon>edit</v-icon></v-list-item-icon>
                      <v-list-item-title>Editar</v-list-item-title>
                    </v-list-item>
                    <v-divider></v-divider>
                  </v-list>
                </v-menu>
              </td>
            </tr>
          </template>
        </v-data-table>
      </template>
    </v-widget>
  </v-container>
</template>

<script>
import { mapState } from 'vuex';
import constantes from '@/util/constantes';

export default {
  data() {
    return {
      headers: [
        { text: 'CNPJ', value: 'cnpj', sortable: false },
        { text: 'Razão Social', value: 'razao', sortable: false },
        { text: 'Modo Utilização', value: 'plano', sortable: false },
        { text: 'Ambiente', value: 'ambiente', sortable: false },
        { text: 'Atual', value: 'atual', sortable: false },
        { text: '', value: 'acoes', align: 'center', sortable: false },
      ],
      constantes: constantes,
      busca: '',
    };
  },

  computed: {
    ...mapState('filial', {
      filiais: 'filiais',
      carregando: 'carregando',
      total: 'totalFiliais',
    }),

    ehEmpresaSelecionada(empresa) {
      return this.$store.state.empresa._id === empresa._id;
    },

    isSm() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },

  methods: {
    novaFilial() {
      this.$store.commit('filial/nova');
      this.$router.push('/filiais/nova');
    },

    editarFilial(filial) {
      this.$store.commit('filial/editar', filial);
      this.$router.push('/filiais/editar');
    },

    carregarFiliais() {
      this.$store.dispatch('filial/carregar', this.options);
    },

    alterarEmpresa(empresa) {
      this.$store.dispatch('alterarEmpresa', empresa);
    },

    remover(empresa) {
      this.$root.$confirmacao.open('Remover', 'Tem certeza que deseja remover?').then((confirmado) => {
        if (confirmado) {
          this.$store.dispatch('filial/remover', empresa);
        }
      });
    },

    mudarEmpresa(empresa) {
      this.$root.$confirmacao.open('Empresa Padrão', 'Tem certeza que deseja definir ' + formatador.formatarCnpj(empresa.cnpj) + ' como empresa padrão?').then((confirmado) => {
        if (confirmado) {
          this.$store.dispatch('definirEmpresa', empresa._id);
        }
      });
    },
  },

  created() {
    //chamada a action através do dispatch
    this.carregarFiliais();
  },
};
</script>
